import { parse } from 'query-string'

export const limit = 10

export const range = (start, end) => {
    return [...Array(end).keys()].map(el => el + start)
}

export const getPaginator = search => {
    const parsedSearch = parse(search)
    const currentPage = parsedSearch.page ? Number(parsedSearch.page) : 1
    const offset = currentPage * limit - limit
    return { currentPage, offset }
}

export const defaultUserImagePath = 'https://static.productionready.io/images/smiley-cyrus.jpg'

export const dateFormating = (str) => {
    const newDate = new Date(str)
    const year = newDate.getFullYear()
    const month = newDate.toLocaleString('en-GB', { month: 'short' })
    const date = newDate.getDate()

    return `${month} ${date}, ${year}`
}

import React from 'react'

const Footer = () => {
    const year = new Date().getFullYear()
    return (
        <footer>
            <div className="container">
                <a className="logo-font" href="/">
                    Medium
                </a>
                <span className="attribution">
                    © {year}. A place to share knowledge
                    <a href="https://github.com/s-p-ko/medium_clone"> <b>s-p-ko</b> </a>
                    . Code licensed under MIT.
                </span>
            </div>
        </footer>
    )
}

export default Footer

import React, { useContext } from 'react'

import { CurrentUserContext } from 'contexts/currentUser'
import { defaultUserImagePath } from 'utils'

const UserImage = ({ className = null, alt = null }) => {
    const [currentUserState] = useContext(CurrentUserContext)
    const path = (currentUserState.isLoggedIn && currentUserState.currentUser.image) || defaultUserImagePath
    const username = currentUserState.currentUser.username
    const alternativeText = alt ?? username

    return (
        <img
            className={className}
            src={path}
            alt={String(alternativeText)}
        />
    )
}

export default UserImage

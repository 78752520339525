import React, { useContext, Fragment } from 'react'
import { NavLink, Link } from 'react-router-dom'

import { CurrentUserContext } from 'contexts/currentUser'
import UserImage from 'components/userImage'
import Logout from 'components/logout'

const TopBar = () => {
    const [currentUserState] = useContext(CurrentUserContext)
    return (
        <nav className="navbar navbar-light">
            <div className="container">
                <Link to="/" className="navbar-brand">
                    Medium
                </Link>
                <ul className="nav navbar-nav pull-xs-right">
                    <li className="nav-item">
                        <NavLink to="/" className="nav-link" exact>
                            Home
                        </NavLink>
                    </li>
                    {currentUserState.isLoggedIn && (
                        <Fragment>
                            <li className="nav-item">
                                <NavLink to="/articles/new" className="nav-link">
                                    <i className="ion-compose" />
                                    &nbsp; New Post
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/settings" className="nav-link">
                                    <i className="ion-gear-a" />
                                    &nbsp; Settings
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    to={`/profiles/${currentUserState.currentUser.username}`}
                                    className="nav-link"
                                    onClick={() => { window.location.href = `/profiles/${currentUserState.currentUser.username}` }}
                                >
                                    <UserImage className="user-pic" />
                                    &nbsp; {currentUserState.currentUser.username}
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <Logout
                                    className="nav-link"
                                    btnStyle={{ border: "none", outline: "none" }}
                                />
                            </li>
                        </Fragment>
                    )}
                    {currentUserState.isLoggedIn === false && (
                        <Fragment>
                            <li className="nav-item">
                                <NavLink to="/login" className="nav-link">
                                    Sign in
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/register" className="nav-link">
                                    Sign up
                                </NavLink>
                            </li>
                        </Fragment>
                    )}
                </ul>
            </div>
        </nav>
    )
}

export default TopBar

import React, { useContext } from 'react'
import classNames from 'classnames'

import { CurrentUserContext } from 'contexts/currentUser'
import useFetch from 'hooks/useFetch'

const AddToFavorites = ({ isFavorited, favoritesCount, articleSlug, articleAuthor }) => {
    const [{ currentUser }] = useContext(CurrentUserContext)
    const canLike = (currentUser !== null) && (articleAuthor !== currentUser.username)

    const apiUrl = `/articles/${articleSlug}/favorite`
    const [{ response }, doFetch] = useFetch(apiUrl)

    const isFavoritedWithResponse = response
        ? response.article.favorited
        : isFavorited

    const favoritesCountWithResponse = response
        ? response.article.favoritesCount
        : favoritesCount

    const buttonClasses = classNames({
        btn: true,
        'btn-sm': true,
        'btn-primary': isFavoritedWithResponse,
        'btn-outline-primary': !isFavoritedWithResponse
    })

    const handleLike = event => {
        event.preventDefault()
        doFetch({
            method: isFavoritedWithResponse ? 'delete' : 'post'
        })
    }
    return (
        <>
            {canLike && (
                <button className={buttonClasses} onClick={handleLike} >
                    <i className="ion-heart"></i>
                    <span>&nbsp; {favoritesCountWithResponse}</span>
                </button>
            )}
            {!canLike && (
                <button className={buttonClasses} disabled >
                    <i className="ion-heart"></i>
                    <span>&nbsp; {favoritesCountWithResponse}</span>
                </button>
            )
            }
        </>
    )
}

export default AddToFavorites

import React from 'react'
import classNames from 'classnames'

import useFetch from 'hooks/useFetch'

const BtnFollow = ({ isFollowing, username }) => {

    const apiUrl = `/profiles/${username}/follow`
    const [{ response }, doFetch] = useFetch(apiUrl)
    const isFavorited = response
        ? response.profile.following
        : isFollowing

    const buttonClasses = classNames({
        btn: true,
        'btn-sm': true,
        'action-btn': true,
        'btn-outline-secondary': !isFavorited,
    })

    const handleFollow = event => {
        event.preventDefault()
        doFetch({
            method: isFavorited ? 'delete' : 'post'
        })
    }

    const isFollow = isFavorited ? `Unfollow` : `Follow`

    return (
        <button className={buttonClasses} onClick={handleFollow}>
            <i className="ion-plus-round"></i>
            <span>&nbsp; {isFollow} {username}</span>
        </button>
    )
}

export default BtnFollow
import React, { useState, useEffect } from 'react'

import ErrorMessage from 'components/errorMessage'
import UserImage from 'components/userImage'

const AddComment = ({ response, error, doFetch }) => {
    const [body, setBody] = useState('')
    const [isSuccessfullSubmit, setIsSuccessfullSubmit] = useState(false)

    const handleSubmit = event => {
        event.preventDefault()
        doFetch({
            method: 'post',
            data: { body }
        })
        setIsSuccessfullSubmit(true)
    }

    useEffect(() => {
        if (!response) {
            return
        }
        if (isSuccessfullSubmit) {
            setIsSuccessfullSubmit(false)
            setBody('')
        }
    }, [response])

    return (
        <div className="col-xs-12 col-md-8 offset-md-2">
            {error && <ErrorMessage />}
            <form
                className="card comment-form ng-untouched ng-pristine ng-valid"
                onSubmit={handleSubmit}
            >
                <fieldset>
                    <div className="card-block">
                        <textarea
                            className="form-control ng-valid ng-untouched ng-pristine"
                            placeholder="Write a comment..."
                            rows="3"
                            value={body}
                            onChange={e => setBody(e.target.value)}
                        ></textarea>
                    </div>
                    <div className="card-footer">
                        <UserImage className="comment-author-img" />
                        <button
                            className="btn btn-sm btn-primary"
                            type="submit"
                        >
                            Post Comment
                        </button>
                    </div>
                </fieldset>
            </form>
        </div>
    )
}

export default AddComment

import React from 'react'
import { NavLink } from 'react-router-dom'

import ErrorMessage from 'components/errorMessage'
import useFetch from 'hooks/useFetch'
import { dateFormating } from 'utils'

const CommentCard = ({ item, url, currentUsername }) => {

    const commentId = item.id
    const apiUrl = `${url}/${commentId}`
    const [{ response, error }, doFetch] = useFetch(apiUrl)
    const handleOnClick = () => {
        doFetch({ method: 'delete' })
    }
    const liStyle = { listStyleType: "none" }

    if (response) {
        return null
    }
    return (
        <>
            {error && <ErrorMessage />}
            <li style={liStyle}>
                <div className="card">
                    <div className="card-block">
                        <p className="card-text">{item.body}</p>
                    </div>
                    <div className="card-footer">
                        <NavLink className="comment-author" to={`/profiles/${item.author.username}`}>
                            <img className="comment-author-img" src={item.author.image} />
                        </NavLink>
                        &nbsp;
                        <NavLink className="comment-author" to={`/profiles/${item.author.username}`}>
                            {item.author.username}
                        </NavLink>
                        <span className="date-posted"> {dateFormating(item.createdAt)} </span>
                        {currentUsername === item.author.username && (
                            <span className="mod-options" >
                                <i className="ion-trash-a" onClick={handleOnClick}></i>
                            </span>
                        )}
                    </div>
                </div>
            </li>
        </>
    )
}
export default CommentCard

import React from 'react'

const Banner = () => {
    return (
        <div className="banner">
            <h1>Medium Clone</h1>
            <p>A place to share knowledge</p>
        </div>
    )
}

export default Banner

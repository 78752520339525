import React, { useEffect, useContext } from 'react'

import AddComments from 'pages/article/components/comments/components/addComment'
import CommentCard from 'pages/article/components/comments/components/commentCard'
import ErrorMessage from 'components/errorMessage'
import Loading from 'components/loading'
import useFetch from 'hooks/useFetch'
import { CurrentUserContext } from 'contexts/currentUser'

const Comments = ({ slug }) => {

    const apiUrl = `/articles/${slug}/comments`

    const [{ response, isLoading, error }, doFetch] = useFetch(apiUrl)
    const [{ response: addCommentResponse, error: addCommentError }, doFetchAddComment] = useFetch(apiUrl)
    const [currentUserState] = useContext(CurrentUserContext)
    const currentUsername = currentUserState.currentUser.username


    useEffect(() => {
        doFetch()
    }, [doFetch, addCommentResponse])

    const ulStyle = {
        marginLeft: 0,
        paddingLeft: 0
    }

    return (
        <>
            <AddComments
                doFetch={doFetchAddComment}
                response={addCommentResponse}
                error={addCommentError}
            />
            <div className="col-xs-12 col-md-8 offset-md-2">
                {isLoading && <Loading />}
                {error && <ErrorMessage />}
                {!isLoading && response && (
                    <ul style={ulStyle}>
                        {response.comments.map(item => (
                            <CommentCard
                                key={item.id}
                                url={apiUrl}
                                item={item}
                                currentUsername={currentUsername}
                            />
                        ))}
                    </ul>
                )}
            </div>
        </>
    )
}

export default Comments

import React, { useContext, useState, useEffect } from 'react'

import { CurrentUserContext } from 'contexts/currentUser'

const Logout = ({
    className = '',
    btnStyle = {},
    text = 'Logout',
    redirect = '/',
    localStorageKey = 'token',
}) => {

    const [, dispatch] = useContext(CurrentUserContext)
    const [successfullLogout, setIsSuccessfullLogout] = useState(false)

    const logout = event => {
        event.preventDefault()
        localStorage.removeItem(localStorageKey)
        setIsSuccessfullLogout(true)
    }

    useEffect(() => {
        if (!successfullLogout) {
            return
        }
        dispatch({ type: 'SET_UNAUTHORIZED' })
    }, [dispatch])

    if (successfullLogout) {
        window.location.href = redirect
    }

    return (
        <button className={className} onClick={logout} style={btnStyle}>{text}</button>
    )
}

export default Logout
